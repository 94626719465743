export const Platforms = {
  ANDROID: 'android',
  IOS: 'ios',
  WEB: 'web',
} as const

export type PlatformType = (typeof Platforms)[keyof typeof Platforms]

export const CloudFrontUserAgents = {
  ANDROID: 'CloudFront Android',
  IOS: 'CloudFront IOS',
} as const

export const CloudFrontHeaders = {
  ANDROID: 'cloudfront-is-android-viewer',
  IOS: 'cloudfront-is-ios-viewer',
} as const

export const getPlatformFromUserAgent = (userAgent: string) => {
  try {
    if (/iPhone|iPad|iPod|IOS|Cypress/i.test(userAgent)) {
      return Platforms.IOS
    } else if (/Android/i.test(userAgent)) {
      return Platforms.ANDROID
    } else {
      return Platforms.WEB
    }
  } catch (error) {
    return Platforms.WEB
  }
}
